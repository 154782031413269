.accordion {
    display: block;

    &-title {
        max-width: calc(100% - 30px);
    }

    &-header {

        .btn {
            display: block;
            width: 100%;
        }

        .c-list-collapse {
            font-size: 20px;
            font-weight: bold;
            color: $black;
            padding: 14px 0;
            text-align: left;

            &-sub {
                font-size: 15px;
                font-weight: normal;
                padding-top: 0;

                &.active {
                    color: $ocean;
                    font-size: 16px;
                }
            }
        }
    }

    &--faq {
        .card {
            overflow: visible;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid $gray-300 !important;
            background-color: transparent;

            &-header {
                padding: 0;
                border-bottom: 0 !important;
                background-color: transparent;
            }

            &-body {
                padding: 0;
            }
        }
    }

    &--legal {
        .card {
            @include makeAcustomCard(0, 0);
            margin-bottom: 1rem;
            border-radius: 8px !important;

            &-header {
                padding: 0 calc(24/16)*1rem;
                border-bottom: 1px solid $gray-200 !important;

                .btn-link {
                    padding: calc(24/16)*1rem 0;
                    font-weight: normal;
                    font-size: $font-size-base;
                    font-family: $font-family-base;
                    color: $body-color;
                }
            }

            &-body {
            }
        }
    }
}
