$loop-max: 110; // taille loop-maximale voulue
$loop-offset: 10; // espacement entre les tailles
$loop-unit: 'px !important'; // possible de mettre des %, vh, vw ou autres mesures
$loop-i: 0; // commence le loop à 0

// PADDING SECTION
@mixin loop-padding-left($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-padding-left('.pl-', 'padding-left');

@mixin loop-padding-right($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-padding-right('.pr-', 'padding-right');

@mixin loop-padding-top($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-padding-top('.pt-', 'padding-top');

@mixin loop-padding-bottom($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-padding-bottom('.pb-', 'padding-bottom');


// MARGIN SECTION
@mixin loop-margin-left($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-margin-left('.ml-', 'margin-left');

@mixin loop-margin-right($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-margin-right('.mr-', 'margin-right');

@mixin loop-margin-top($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-margin-top('.mt-', 'margin-top');

@mixin loop-margin-bottom($className, $styleName) {
    @while $loop-i <= $loop-max {
        #{$className + $loop-i} {
            #{$styleName}: #{$loop-i + $loop-unit};
        }
        $loop-i: $loop-i + $loop-offset;
    }
}

@include loop-margin-bottom('.mb-', 'margin-bottom');


// -----------------------------------------------------------------------------

// model des card du site
@mixin makeAcard($paddingTop, $paddingBottom) {
    border: 0 !important;
    border-radius: 8px;
    background-color: $white !important;
    padding-top: $paddingTop !important;
    padding-bottom: $paddingBottom !important;
}

@mixin makeAcustomCard($paddingV, $paddingH) {
    border: 0;
    border-radius: 8px;
    background-color: $white;
    padding: $paddingV $paddingH;
    box-shadow: 0 1px 10px 0 rgba($black, 0.08);
}

// model des listes du site
@mixin makeAlist($radius, $paddingTop, $paddingRight, $paddingBottom, $paddingLeft, $marginBottom, $cursor) {
    border: 0 !important;
    border-radius: $radius !important;
    background-color: $white !important;
    padding-top: $paddingTop !important;
    padding-right: $paddingRight !important;
    padding-bottom: $paddingBottom !important;
    padding-left: $paddingLeft !important;
    margin-bottom: $marginBottom !important;
    cursor: $cursor;
}

// model des liens
@mixin makeAlinkSimple {
    color: $blue-mid !important;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        color: $blue-dark !important;
    }
}

@mixin makeAbreadcrumb {
    color: $grey !important;
    font-size: 12px !important;
    cursor: pointer !important;
}

// model des bouton
@mixin makeAbutton($color1, $color2, $color3, $color4, $color5, $color6, $cursor) {
    border: solid 2px $color1;
    background-color: $color2;
    color: $color3;
    padding: 15px 40px;
    border-radius: 25px;
    cursor: $cursor;

    &:hover {
        border: solid 2px $color4;
        background-color: $color5;
        color: $color6;
    }
}

// model des boutons 2.0
@mixin makeAnewButton($cursor) {
    background: $ocean-dark;
    background: linear-gradient(to right, $ocean-dark, $ocean);

    color: $white !important;
    padding: 15px 40px !important;
    cursor: $cursor;

    &:hover {
        background: linear-gradient(to right, $ocean-dark, $ocean-dark);
        color: $white !important;
    }
}

/*
* Toutes les mixins du site
*/

@mixin makeCard($radius, $background) {
    border-radius: $radius !important;
    background-color: $background;
}

@mixin makeInput($radius, $background, $border) {
    border-radius: $radius !important;
    background-color: $background;
    border: $border;
}

@mixin boxShadow($offsetX, $offsetY, $blurRadius, $color) {
    box-shadow: $offsetX $offsetY $blurRadius $color;
}

@mixin gradient($startColor, $endColor, $orientation) {
    background: $startColor;
    background: linear-gradient(to right, $startColor, $endColor);
}
