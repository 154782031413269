.rounded-xs {
    @include media-breakpoint-only(xs) {
        border-radius: $border-radius !important;
    }
}

.link-overlay {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    cursor: pointer;
}

.smaller {
    font-size: calc(12/16)*1rem;
    line-height: calc(14 / 12);
}

.cursor-pointer {
    cursor: pointer;
}

.bs {
    box-shadow: 0 1px 10px 0 rgba($black, 0.08);
}

.border-lg {
    @include media-breakpoint-up(xl) {
        border: $border-width solid $border-color !important;
    }
}

.flex-adapt {
    min-width: max-content;
}
