footer {
    &.footer {
        background-color: #1b1b1b;
        padding-top: 50px;
        padding-bottom: 30px;
        color: $white;
        position: relative;

        > .container > .row > div {
            @include media-breakpoint-down(md) {
                &:nth-child(3) {
                    order: 3;
                }
                &:nth-child(4) {
                    order: 2;
                }
            }
        }

        .footerTitle {
            font-size: calc(32/16)*1rem;
            line-height: calc(40/16)*1rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            text-align: center;
            color: $white;
            margin-top: calc(20/16)*1rem;
            margin-bottom: 1rem;

            .version {
                font-size: 0.7rem;
            }
        }

        .pts-Footer-toTop {
            transition: .5s ease-in-out;
            z-index: 1010;

            @include media-breakpoint-only(xs) {
                padding-right: calc(8/16)*1rem;
                padding-left: calc(8/16)*1rem;
            }

            @include media-breakpoint-only(xs) {
                position: absolute;
                top: 45px;
                right: 0;
                left: auto;
                width: auto;
            }

            @media (pointer: fine) {
                a {
                    &:hover {
                        background-color: rgba(#fff, .08);
                        border-radius: calc(20/16)*1rem;
                        text-decoration: none !important;
                    }
                }
            }
        }

        .mainFooterNavbar {
            margin-bottom: 60px;

            .nav-link {
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $white;
                padding: 0.5rem 15px;

                @media (pointer: fine) {
                    &:hover {
                        background-color: rgba(#fff, .08);
                        border-radius: 1rem;
                    }
                }
            }

            @include media-breakpoint-only(xs) {
                justify-content: flex-start !important;
                margin-bottom: 40px;
            }
        }

        .secondaryFooterNavbar {
            background-color: $black;
            margin-bottom: 34px;
            padding-right: calc($grid-gutter-width / 2);
            padding-left: calc($grid-gutter-width / 2);

            @include media-breakpoint-up(sm) {
                > .container {
                    padding-right: calc($grid-gutter-width / 2);
                    padding-left: calc($grid-gutter-width / 2);
                }
            }

            @include media-breakpoint-up(md) {
                height: calc(64/16)*1rem;
            }

            .navbar-nav {
                flex-wrap: wrap;
            }

            .nav-item {
                position: relative;

                &:not(:last-of-type) {
                    &:after {
                        border-right: solid 1px #808084;
                        position: absolute;
                        right: 0;
                        top: 10px;
                        content: '';
                        height: 15px;
                        width: 1px;
                        opacity: .35;
                    }
                }
            }

            .nav-link {
                padding: .5rem calc($grid-gutter-width / 2);
                font-size: calc(14/16)*1rem;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #808084;
                white-space: nowrap;

                @include media-breakpoint-only(md) {
                    padding: .5rem calc($grid-gutter-width / 3);
                    margin: 0 calc(5/16)*1rem;
                }

                @include media-breakpoint-up(lg) {
                    padding: .5rem calc(10/16)*1rem;
                    margin: 0 calc(10/16)*1rem;
                }

                @media (pointer: fine) {
                    &:hover {
                        background-color: rgba(#fff, .12);
                        border-radius: 1rem;
                    }
                }
            }

            @include media-breakpoint-only(xs) {
                justify-content: flex-start !important;
                padding-top: 20px;
                padding-bottom: 20px;

                .nav-item {
                    position: relative;

                    &:not(:last-of-type) {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .logoFooter {
            display: block;
            margin: -17px 15px 0 -5px;

            @include media-breakpoint-only(xs) {
                margin: 10px 5px 15px -5px;
            }
        }

        .legals {
            font-size: calc(14/16)*1rem;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.15;
            letter-spacing: normal;
            text-align: center;
            color: #929292;
            margin-bottom: 30px;

            .mobile {
                display: none;
            }


            @include media-breakpoint-only(xs) {
                .mobile {
                    display: initial;
                }
            }
        }
    }
}
