[role="button"] {
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

.c-btn, .btn {
    font-family: $headings-font-family;
}

.btn-outline-info {

    &.btn.disabled,
    &.btn:disabled {
        background: $gray-200;
        cursor: not-allowed !important;
        color: $gray-600 !important;
        border: transparent;
    }
}

.c-btn {

    &-primary {

        @include makeAbutton($blue-mid, $blue-mid, $white, $blue-dark, $blue-dark, $white, pointer);

        &[disabled] {
            cursor: not-allowed !important;
        }

    }

    &-ocean {
        /* ==============================================================
        BTN
        ===============================================================*/

        @include makeAnewButton(cursor);

        font-size: 14px;

        &.btn.disabled,
        &.btn:disabled {
            background: $gray-200;
            cursor: not-allowed !important;
            color: $gray-600 !important;
        }

        &.parloirs {
            padding: 10px 30px !important;
        }
    }

    &.with-picto {

        &--parloirs {

            &:hover i {
                filter: brightness(0) invert(1);
            }
        }

        .picto {

            &.utils {

                background-size: 20px 20px !important;
                background-position: center !important;
                transform: rotate(0deg);
                width: 20px;
                height: 20px;

                &.card {
                    background: url('../../content/images/pictos/card.svg') no-repeat center center;
                }

                &.calendar {
                    background: url('../../content/images/pictos/calendar-small-white.svg') no-repeat center center;
                }

                &.noPermis {
                    background: url('../../content/images/pictos/utils/noPermis.svg') no-repeat center center;
                }
            }
        }
    }

    &-secondary {

        @include makeAbutton($blue-mid, $white, $blue-mid, $blue-dark, $white, $blue-dark, pointer);

        &[disabled] {
            cursor: not-allowed !important;
        }
    }

    &-other {

        @include makeAbutton($grey-light, $grey-light, $grey, $grey-mid, $grey-mid, $grey, pointer);

        border-radius: 6px !important;
        position: relative !important;

        &[disabled] {
            cursor: not-allowed !important;
        }

        padding: 7px 17px !important;

        .badge {
            position: absolute !important;
            top: -10px !important;
            padding: 5px 8px !important;
            right: -10px !important;
            border-radius: 50% !important;
        }
    }

    &:focus {
    }
}

/*.c-link {

    @include makeAlinkSimple;

    &-dark {
        color: $black !important;
        padding: 0 !important;
        font-weight:bolder !important;

        &:hover {
            color: lighten($black, 50%) !important;
        }
    }
}*/

.c-switch {

    &-active {

    }

    &-inactive {

    }
}

.badge {

    &.btn {

        &.no-btn {
            -webkit-appearance: button !important;
            background: $grey-light !important;
            color: $black !important;
            padding: 0 15px !important;
            margin-bottom: 10px !important;
            margin-right: 20px !important;
            font-size: 14px;
            line-height: 40px;
            font-weight: normal;

            strong {
                font-size: 18px;
                line-height: 40px;
                padding-left: 15px;
            }
        }
    }
}

.btn {

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    &[disabled] {
        background-color: $gray-200 !important;
        border-color: $gray-200 !important;
        color: $gray-500 !important;
    }

    &.btn-transparent {

        &.dropdown-toggle {

            &:after {
                display: none;
            }
        }
    }

    &.with-picto {
        fa-icon {
            @include media-breakpoint-only(xs) {
                font-size: 1.5rem;
            }
        }
    }

    &[aria-expanded=true] {
        ned-icon[type=caret-down] {
            transform: scaleY(-1);
        }
    }

    &-default-hover {
        &:hover {
            background-color: $gray-100;
        }
    }

    &.filtre {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 12px 4px 8px;
        height: 32px;
        border-radius: 16px;

        &.en-cours {
            background: $yellow-tournesol-950;
            color: $yellow-tournesol-sun-407;
        }

        &.execute {
            background: $blue-filter;
            color: $success;
        }

        &.rejete {
            background: $red-filter;
            color: $danger;
        }

        &.selectionne {
            &.en-cours {
                background: $yellow-tournesol-sun-407;
                color: $inverted-blue-france;
            }

            &.execute {
                background: $green-success;
                color: $inverted-blue-france;
            }

            &.rejete {
                background: $danger;
                color: $inverted-blue-france;
            }
        }
    }
}

.badge {
    white-space: normal !important;
}
