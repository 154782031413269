// non connecté
.heros {
    padding: 0 !important;
    margin: 0 !important;
    background-image: url("/content/images/illustrations/heros-1.jpg");
    background-size: cover;
    background-size: calc(100% + 50px) auto;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -40px !important;

    @include media-breakpoint-only(xs) {
        background-size: auto calc(100% + 50px);
    }

    article {
        height: 360px !important;

        .c-title {
            background-color: rgba(#fff, .75);
        }
    }
}

.services {
    background: $ocean;

    .c-card {

        &-body {
            padding: 0 !important;
            margin: 0 15px !important;
        }

        &-title {
            font-size: calc(24/16)*1rem !important;
            font-weight: bold !important;
            text-align: center !important;
        }

        &-text {
            font-size: 14px !important;
            line-height: 16px !important;
        }

        &-text-sec {
            font-size: 12px !important;
            line-height: 20px !important;
        }

        &-footer {
            padding: 0;
            background: transparent;
            border: 0;
        }
    }


    @media (min-width: 576px) and (max-width: 991px) {

        .card-deck {
            margin-right: -15px !important;
            margin-left: -15px !important;
            display: flex;
            justify-content: space-between !important;

            .c-card {

                &:not(:last-of-type) {
                    max-width: 49% !important;
                    flex: auto !important;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                    margin-bottom: 15px;
                }

                &:last-of-type {
                    width: 50% !important;
                    flex: none !important;
                    display: block !important;
                    margin: auto !important;
                }

                .card-footer {

                    &.c-card-footer {
                        margin: auto;
                    }
                }
            }
        }
    }

}
