@import 'bootstrap/scss/variables';
@import "../bootstrap-variables";
@import "./variables";

.nav-link, a[href], .c-link, .btn, [tabindex="0"], .close {
    &:not(.form-check-input):focus {
        outline: none;
        box-shadow: $btn-focus-box-shadow;

        &:not(:focus-visible) {
            box-shadow: none;
        }
    }
}

.dropdown-toggle {
    &:focus {
        outline: none;
        box-shadow: $btn-focus-box-shadow;

        &:not(:focus-visible), &[aria-expanded="true"] {
            box-shadow: none;
        }
    }
}

.close {
    &:focus {
        outline: none;
    }
}
