// TYPOGRAPHY:

// liens
a {
    @at-root .navbar-nav &,
    .pts-Footer-toTop &,
    .nav-tab-custom &,
    &.c-card-small--link {
        text-decoration: none;
    }
}

// titre d'une page
.c-title {
    margin: 0;
    color: $ocean;
    font-size: calc(30/16) * 1rem;
    font-weight: bold;

    &-autocompletion {
        font-size: 14px;
    }

    &-not-connected {
        font-size: calc(40/16) * 1rem;
    }

    &-services {
        color: $white;
    }

    &-contrast {
        background-color: rgba(#fff, .5);
    }
}

// sous-titre d'une page
.c-subTitle {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;

    &--light {
        color: $blue-mid;
    }

    &--dark {
        color: $dark-grey;
    }

    &-autocompletion {
        font-size: 12px;

        &-other {
            color: $grey-mid;
        }
    }

    &-versement {
        font-size: 16px;
        font-weight: bold;

        @media (max-width: 575.98px) {
            font-size: 14px;

            & + .c-text {
                font-size: 12px;
            }
        }
    }

    &-xs {

        @media (max-width: 575.98px) {
            font-size: 16px;
        }
    }
}

// sous-titres nb RDV parloir et dates de validite
.c-subTitleInfosPVExceptionnel {
    margin: 0;
    font-weight: bold;
    font-size: 15px;
    line-height: 21px;
}

.c-text {
    font-size: 14px;

    &-titre {
        font-weight: bold;
        font-family: 'robotoregular', sans-serif;
    }

    &-error {
        color: $red-mid;
    }

    &-home {
        font-size: 18px;
        line-height: 20px;
        font-weight: normal;

        &-bold {
            font-weight: bold;
            font-size: 16px;
        }
    }

    &-grey {
        color: $text-grey;
    }

    &-bold {
        font-weight: bold;
        font-size: 16px;
    }
}

.c-h4 {
    font-size: 14px;
    color: $text-grey;
    letter-spacing: .3px;

    &-black {
        color: $black;
        font-weight: bold;
    }
}

.c-link,
.c-link span {
    color: $ocean !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-decoration: none !important;
    cursor: pointer !important;

    &.underline {
        text-decoration: underline !important;

        &:hover {
            text-decoration: none !important;
        }
    }
}

h1 {
    &.custom {
        &.titlePopin {
            font-size: 32px;
            font-weight: bold;
            line-height: 30px;
            color: $black;
            margin: 0;
            padding: 0;
        }
    }
}

h1 {
    &.custom {
        &.titlePage {
            font-size: 40px;
            font-weight: bold;
            color: $black;
            margin: 0;
            padding: 0;

            @include media-breakpoint-only(xs) {
                font-size: 2rem;
                line-height: 1.25;
            }
        }
    }
}

h2 {
    &.custom {
        &.subTitlePage {
            font-size: 25px;
            font-weight: bold;
            line-height: 25px;
            color: $dark-grey;

            @include media-breakpoint-only(xs) {
                font-size: calc(14/16)*1rem !important;
                line-height: 1 !important;
            }
        }

        &.noPermis {
            font-size: 25px;
            font-weight: bold;
            line-height: 25px;
            text-align: center;
            color: $dark-grey;
        }
    }
}

h3 {
    &.custom {
        &.subTitlePage {
            font-size: 20px;
            font-weight: bold;
            line-height: 20px;
        }
    }
}

.h4 {
    &:not(._) {
        line-height: calc(28 / 22);
        @include media-breakpoint-only(xs) {
            font-size: calc(20/16)*1rem;
            line-height: calc(28 / 20);
        }
    }
}

p {
    margin-bottom: 0 !important;
}

.color-red {
    color: #F00;
}

.text-ocean {
    color: $ocean !important;
}

.format-ul {
    margin: 0;
}

.lh1 {
    line-height: 1;
}
