// infinite scroll design pour webkit
.infiniteScrollTable::-webkit-scrollbar-track {
    background-color: rgba($text-grey, 0.3);
    border-radius: 5px;
}

.infiniteScrollTable::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    background-color: $grey-light;
}

.infiniteScrollTable::-webkit-scrollbar-thumb {
    background-color: $dark-grey;
    border-radius: 10px;
}
