.spinner {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: url("../../content/images/spinner.svg") no-repeat;
    background-position: center center;
    height: 150px;
    width: 150px;
    z-index: 99999;
}

.overlay {
    width: 100vw;
    height: 100vh;
    filter: blur(5px);
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}
