@include media-breakpoint-down(md) {

    // home non connecté

    .c-title-not-connected {
        font-size: 30px;
    }

    .mt-xs-20,
    .mt-sm-20 {
        margin-top: 20px;
    }

    .text-xs-center {
        text-align: center !important;
    }

    .c-card {

        &-small {
            border-radius: 0 !important;

            &-exception {

                &-calendar {
                    padding: 30px 20px !important;
                }
            }
        }
    }

    .custom.calendrier {

        > div {

            &:nth-of-type(3) {

                margin: 0 5px;
            }
        }

        li.calendrier_jour {
            //width: 80px !important;
            //height: 45px;
            padding: 0 .75rem;
            text-align: center !important;
        }
    }

    .mr-xs-0,
    .me-5.mr-xs-0,
    .me-2.mr-xs-0 {
        margin-right: 0 !important;
    }

    .mr-xs-10 {
        margin-right: 10px !important;
    }

    .mt-xs-20 {
        margin-top: 20px !important;
    }

    .mt-xs-30 {
        margin-top: 30px !important;
    }

    .mb-xs-20 {
        margin-bottom: 20px !important;
    }
    .pv-xs-20,
    .c-btn.pv-xs-20 {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .flex-xs-column {
        flex-direction: column !important;
    }

    .justify-content-xs-center,
    .justify-content-between.justify-content-xs-center,
    .justify-content-start.justify-content-xs-center {
        justify-content: center !important;
    }

    .flex-xs-column-reverse {
        flex-direction: column-reverse !important;
    }

    .text-xs-center {
        text-align: center !important;
    }

    // card RDV futurs
    .d-flex {

        &.align-items-center {

            &.flex-xs-column {
                flex-direction: column !important;
                justify-content: left !important;
            }
        }
    }

    // card Permis
    .col-xs-12 {
        &.col-md-12 {
            &.col-lg-9 {

                .row {

                    > div {
                        justify-content: center !important;

                        &:nth-of-type(2) {
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
    }

    .col-xs-12 {
        &.col-md-12 {
            &.col-lg-3 {
                &.justify-content-xs-center {
                    margin-top: 20px;
                    justify-content: center !important;

                    aside {
                        flex-direction: column !important;
                    }

                }
            }
        }
    }

    .footerTitle {
        font-size: 34px !important;
        line-height: 34px !important;
    }

    .mt-xs-5 {
        margin-top: 3rem !important;
    }

    .ml-xs-0 {
        margin-left: 0 !important;
    }

    .word-break {
        word-break: keep-all !important;
    }

    .w-20 {
        width: 20px;
    }

    .text-xs-center {
        text-align: center;
    }

    .justify-content-xs-center.justify-content-end {
        justify-content: center !important;
    }

    .border-right.active {
        border-right: 0 !important;
    }

    .hideMobile {
        display: none !important;
    }

    .text-center-sm {
        text-align: center;
    }

    .col-xs-2 {
        flex: 0 0 16.6666666667% !important;
        max-width: 16.6666666667% !important;
        display: flex;
        align-items: center;
    }

    .col-xs-10 {
        flex: 0 0 83.3333333333% !important;
        max-width: 83.3333333333% !important;
        display: flex;
        align-items: center;
    }

    .c-card.reduce-xs {
        padding: 30px 20px !important;

    }
}

@include media-breakpoint-only(sm) {
    .mt-sm-20,
    .mt-md-20 {
        margin-top: 20px;
    }

    .text-md-center {
        text-align: center !important;
    }

    // card RDV futurs
    .d-flex {

        &.align-items-center {

            &.flex-xs-column {
                flex-direction: column !important;
                justify-content: center !important;

                .d-flex {
                    flex-direction: column !important;
                    justify-content: center !important;

                    div {

                        &:first-of-type {
                            margin-right: 0 !important;
                            margin-bottom: 20px !important;
                        }

                        &:last-of-type {

                            section {

                                div {
                                    margin-bottom: 0 !important;

                                    &:first-of-type {
                                        flex-direction: row !important;
                                    }
                                }
                            }
                        }
                    }
                }

                .d-flex.align-items-center.ms-auto {
                    justify-content: center !important;
                    margin-left: 0 !important;
                }
            }
        }

        &.justify-content-between {

            &.flex-xs-column {
                flex-direction: column !important;
                justify-content: center !important;
            }

            .mt-xs-20 {
                margin-top: 20px;
            }
        }
    }

    // card Permis
    .col-xs-12 {
        &.col-md-12 {
            &.col-lg-9 {

                .row {

                    > div {
                        justify-content: center !important;

                        &:nth-of-type(2) {
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
    }

    .col-xs-12 {
        &.col-md-12 {
            &.col-lg-3 {
                &.justify-content-xs-center {
                    margin-top: 20px;
                    justify-content: center !important;

                    aside {
                        flex-direction: column !important;
                    }

                }
            }
        }
    }

    // home connecté
    .d-flex {
        &.justify-content-between {
            &.flex-xs-column {
                &.flex-middle-column {

                    .d-flex {
                        &.mr-30 {
                            &.mr-xs-0 {
                                margin-right: 0 !important;
                            }
                        }
                    }

                    .d-flex {
                        &.align-items-center {
                            &.justify-content-xs-between {

                                button {

                                    &:first-of-type {
                                        margin-right: 0 !important;
                                        margin-bottom: 20px !important;
                                    }
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    .badge.badge-custom.p-3.ps-4.pe-4.me-2.mr-xs-0.mb-2 {
        margin-bottom: 0.5rem !important
    }

    .custom.calendrier {

        .creneaux {
            li.calendrier_jour {
                padding-top: .75rem !important;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .d-sm-none {
        display: none !important;
    }
}

@include media-breakpoint-only(md) {
    header {

        .dropdown-menu {
            will-change: transform !important;
            transform: translate3d(0px, 0px, 0px) !important;
            right: 0;
        }

    }

    .group-mobile {
        background-size: 55px 20px !important;
        width: 55px !important;
        height: 20px !important;
        display: inline-block;
        margin: auto;
        background: url('../../content/images/pictos/divers/groupmobile.png') no-repeat center center !important;
    }

    .mb-tab-0 {
        margin-bottom: 0 !important
    }

    .custom.calendrier {

        .creneaux {
            li.calendrier_jour {
                height: 45px;
                text-align: center !important;
                padding-top: .75rem !important;
            }
        }
    }
}

@include media-breakpoint-between(sm, md) {

    .flex-xs-column.flex-middle-column {
        flex-direction: column !important;
    }

    .d-flex.flex-column.flex-middle-row {
        flex-direction: row !important;
    }

    .mt-middle-20 {
        margin-top: 20px !important;
    }
    .mr-middle-20 {
        margin-right: 20px !important;
    }

    .d-flex.flex-column.justify-content-middle-between,
    .d-flex.flex-xs-column.text-xs-center.justify-content-md-between,
    .d-flex.flex-xs-column.justify-content-md-between {
        justify-content: space-between !important;
    }

    .mr-middle-60 {
        margin-right: 60px !important;
    }

    .mt-middle-20 {
        margin-top: 20px !important;
    }

    .d-middle-none {
        display: none !important;
    }

    .d-flex.d-inline-middle-flex {
        display: inline-flex !important;
    }

    .justify-content-middle-center {
        justify-content: center !important;
    }

    .mb-tab-0.mr-middle-20 {
        margin-bottom: 0 !important
    }
}

@include media-breakpoint-down(md) {

    .card {
        &-body {
            &:not(._) {
                padding: $card-spacer-y calc(12/16)*1rem;
            }
        }
    }

    .disabled-column {

        &.navbar-nav {
            flex-direction: row !important;
        }
    }

    .header .dropdown-menu {
        border: 0 !important;
    }

    .same-size-responsive {

        flex-direction: column !important;
        justify-content: center !important;

        button {
            width: 100%;

            &:last-of-type {
                margin-top: 20px;
            }
        }
    }

    .c-card {

        &.c-card-small {

            &.c-card-small-exception {

                &.c-card-small-exception-calendar {
                    padding: 30px 20px !important;
                }
            }
        }
    }

    .d-xs-none.chevron {
        display: none;
    }
}

@include media-breakpoint-up(md) {

    header {
        .d-f-destkop {
            flex: 1 1 0 !important;
            display: flex !important;
        }
    }
}

.ie {

    .card-created {

        height: calc(460/16)*1rem;
        align-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        display: flex;
        padding: 30px 15px;
        background: $white;
        border-radius: 8px;

        font-size: 14px;
        line-height: 16px;

        .c-card-title {
            font-size: calc(18/16)*1rem;
            font-weight: 700;
            text-align: center;
        }
    }

    > article {

        &.col-md-6.col-lg-4 {

            &:nth-of-type(3) {

                margin-left: auto;
                margin-right: auto;

            }
        }
    }
}

@include media-breakpoint-down(sm) {

    .custom.calendrier {

        .creneaux {
            li.calendrier_jour {
                height: 45px;
                text-align: center !important;
            }
        }
    }
}

@include media-breakpoint-down(sm) {

    .creneaux.col-md-2.pl-0.pr-0 {
        border-left: 1px solid $white;
        border-right: 1px solid $white;
    }
}

// home specifique size

@media (max-width: 360px) {

    footer {

        &.footer {

            .footerTitle {
                font-size: calc(28/16)*1rem !important;
            }
        }
    }
}

@media (max-width: 300px) {

    footer {

        &.footer {

            .footerTitle {
                font-size: calc(20/16)*1rem !important;
            }
        }
    }
}

@media (max-width: 450px) {

    //mon compte flag input
    .forMobileOnly {

        display: inline-flex !important;

        .input-group-prepend {
            flex-direction: column;
        }

        .input-group-prepend + input {
            flex: 1 1 auto !important;
        }
    }

    //faq etab trop long
    .hideMobile {

        input {
            font-size: calc(12/16)*1rem !important;
            text-align: center;
        }
    }

    .dropdown-menu.show {

        width: 100% !important;

        button {
            font-size: calc(12/16)*1rem !important;
        }
    }

    .d-flex.pb-2.flex-fill {
        flex-direction: column;

        span {
            justify-content: center;
            padding-right: 0 !important;
            font-size: calc(15/16)*1rem !important;
        }
    }

    #ngb-typeahead-0.dropdown-menu {
        margin-left: 0 !important;
        overflow: hidden;
    }

    #ngb-typeahead-1,
    #ngb-typeahead-0 {
        overflow: hidden;
    }

    //popin 2 choix
    .two-choice {
        .flex-column-mobile {
            flex-direction: column;

            button {
                &.me-3 {
                    margin-right: 0 !important;
                    margin-bottom: 20px !important;
                }
            }
        }
    }
}

@media (max-width: 370px) {
    //RDV passé alignement soucis
    .inMobile {
        flex-direction: column !important;

        span {
            font-size: calc(15/16)*1rem;
        }
    }
}

.max-with-300-custom {
    max-width: 300px;
}

.top-auto {
    top: auto !important;
}
