.breadcrumb {
    margin-bottom: 40px !important;
    padding-bottom: 0 !important;
    background: transparent !important;

    &-item {

        @include makeAbreadcrumb;


        &.active {

        }

        &:not(:first-of-type) {

            &:before {
                content: ' > ' !important;
                padding-right: .5rem !important;
            }
        }

        &:not(.active) {
            text-decoration: underline !important;
        }
    }

}
