/* ==============================================================
ALERTE section
===============================================================*/
.alerts {
    position: absolute;
    left: 0;
    width: 100vw;
    z-index: 1030;

    @at-root :not(.mainContent) & {
        top: 94px;
    }
    @at-root .mainContent & {
        top: 0;
    }

    &.ribNonDispo {
        top: 0;

        .alert {

            p {
                max-width: 85%;
            }

            p, button {
                display: inline-block;
            }
        }
    }

    > div {
        width: 100vw;
    }

    .alert {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        opacity: 1;
        animation: appearFromBottom .7s ease;
        transform-style: preserve-3d;
        animation-fill-mode: forwards;
        background-color: $white;
        border-color: $white;
        filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.08));

        &-danger {
            border-left: calc(8/16)*1rem solid $danger;
            border-color: $danger;

            p::before {
                content: url('../../content/images/pictos/alert-danger.svg');
            }

            p, .close-alert {
                color: $danger;
            }
        }

        &-warning {
            border-left: calc(8/16)*1rem solid $warning;
            border-color: $warning;

            p::before {
                content: url('../../content/images/pictos/alert-warning.svg');
            }

            p, .close-alert {
                color: $warning;
            }
        }

        &-info {
            border-left: calc(8/16)*1rem solid $info;
            border-color: $info;

            p::before {
                content: url('../../content/images/pictos/alert-info.svg');
            }

            p, .close-alert {
                color: $info;
            }
        }

        &-success {
            border-left: calc(8/16)*1rem solid $success;
            border-color: $success;

            p::before {
                content: url('../../content/images/pictos/alert-success.svg');
            }

            p, .close-alert {
                color: $success;
            }
        }

        &-dismissible {
            padding-right: 5px;
        }

        .btn-close {
            display: none;
        }

        p {
            font-size: 1rem;
            font-weight: 600;
            line-height: calc(24 / 16);
            margin: 0;

            &::before {
                display: inline-block;
                margin-top: 4px;
                padding-left: calc(12/16)*1rem;
                padding-right: calc(8/16)*1rem;
                vertical-align: sub;
            }

            @include media-breakpoint-only(xs) {
                width: 85%;
            }
        }
    }
}

.ribNonDispo {
    margin-top: -40px !important;
    margin-bottom: 40px !important;

    .alert {
        p, button {
            display: inline-block;
        }
    }

    .alert {
        padding: 22px 40px;
        margin: 0;
        border-right: 0;
        border-bottom: 0;
    }

}

.close-alert {
    width: auto;
    text-shadow: none;
    opacity: 1;
    font-weight: bold;
    position: relative;
    line-height: 1.4;
    font-size: 1rem;
    margin-left: 1rem;
    padding: 4px;

    span {
        font-weight: lighter;
    }

    &:hover:not(:active) {
        > * {
            text-decoration: underline;
        }
    }
}
