@font-face {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    src: url("/content/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("/content/fonts/roboto-regular-webfont.woff") format("woff"),
    url("/content/fonts/roboto-regular-webfont.ttf") format("ttf");
}

@font-face {
    font-family: 'ralewaymedium';
    src: url('/content/fonts/raleway-medium-webfont.woff2') format('woff2'),
    url('/content/fonts/raleway-medium-webfont.woff') format('woff'),
    url('/content/fonts/raleway-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}


@import './bootstrap-variables';
@import 'bootstrap/scss/bootstrap';

//desole yoann il etait 19H :)
a svg:hover #colorHoverMobile {
    fill: #2183F0;
}

@import './abstracts/variables';
@import './abstracts/animations';
@import './abstracts/typography';
@import './abstracts/mixins';
@import './abstracts/placeholders';
@import './abstracts/focus';
@import './utils';

@import './layout/header';
@import './layout/footer';

@import './layout/responsive';
@import './layout/flags/flag-icon';

@import './components/accordion';
@import './components/buttons';
@import './components/nav';
@import './components/breadcrumb';
@import './components/switch';
@import './components/forms';
@import './components/pictos';
@import './components/close';
@import './components/alerts';
@import './components/blockquote';
@import './components/illustrations';
@import './components/infiniteScrollTable';
@import './components/composants';
@import './components/loading';


@import './pages/home';
