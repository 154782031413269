@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import './abstracts/variables';

/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

// Colors:
// Grayscale and brand colors for use across Bootstrap.

$primary: #3A58C7;
$secondary: #494F57;
$success: #18753C;
$info: #007E96;
$warning: #B34000;
$danger: #DC2203;
$blue: #0A76F6;
$yellow: #FBD335;

$gray-100: #EEF1F6;
$gray-200: #e5e5e5;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #929292;
$gray-600: #868686;
$gray-700: #434A5B;
$gray-800: #3A3A3A;
$gray-900: #212529 !default;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $gray-100,
    "dark": $dark
);


// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;
$enable-validation-icons: false;

// grid
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
) !default;

// Links
//
// Style anchor elements.

$link-color: $info;
$link-decoration: underline;
$link-hover-color: $link-color;
$link-hover-decoration: none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

$btn-link-color:              var(--#{$prefix}link-color) !default;
$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:     $gray-600 !default;
$btn-link-focus-shadow-rgb:   to-rgb(mix(color-contrast($link-color), $link-color, 15%)) !default;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.5rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;
$border-color: $gray-200;

// Body:
// Settings for the `<body>` element.

//$body-bg: #f1f3f4;

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-base: 'robotoregular', sans-serif;
$small-font-size: calc(14/16*100)*1%;
$headings-font-family: 'ralewaymedium', sans-serif;
$headings-font-weight: 700;
$headings-line-height: 1.2;

$h1-font-size: calc(40/16)*1rem;
$h2-font-size: calc(28/16)*1rem;
$h3-font-size: calc(24/16)*1rem;
$h4-font-size: calc(22/16)*1rem;
$h5-font-size: calc(20/16)*1rem;
$h6-font-size: $font-size-base !default;

// Components
//
// Define common padding and border radius sizes and more.

$navbar-padding-x: $spacer;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
$input-btn-focus-width: .25rem;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-focus-box-shadow: 0 0 0 2px rgba($white, .5), 0 0 0 $input-btn-focus-width $blue;
$btn-border-radius: calc(25/16)*1rem;
$btn-padding-x: 1.3rem;
$btn-padding-y: calc(11/16)*1rem;
$btn-font-weight: 600;
$btn-font-size: calc(14/16)*1rem;
$btn-disabled-opacity: 1;
$btn-line-height: 1.25;
$btn-font-size: calc(14/16)*1rem;

// $btn-padding-y-lg: 1.6rem;
// $btn-padding-x-lg: 1.3rem;
// $btn-font-size-lg: calc(14/16)*1rem;
$btn-border-radius-lg: .3rem;

// Forms

$input-border-width: 0;
$input-font-weight: $font-weight-base;
$input-font-size: 1rem;
$input-font-size-lg: 1rem;
$input-line-height: 1.5;
$input-color: $black;
$input-bg: #EEF1F6;
$input-focus-box-shadow: $btn-focus-box-shadow;
$input-height: calc(2.25rem + 2px);
$input-height-lg: 3rem;
$input-padding-y: calc(9.5/16)*1rem;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-width: 0;
$custom-select-focus-box-shadow: $btn-focus-box-shadow;
$custom-select-background: str-replace(url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.99999 8.78047L11.3 5.48047L12.2427 6.42314L7.99999 10.6658L3.75732 6.42314L4.69999 5.48047L7.99999 8.78047Z' fill='black'/%3E%3C/svg%3E%0A"), '#', '%23') no-repeat right 1rem center;
$custom-select-bg: $input-bg;

$form-check-input-border: solid 1px $gray-800;

// Navs

$nav-link-padding-y: calc(10/16)*1rem;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: calc(18/16)*1rem;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-divider-color: $gray-200 !default;
$nav-divider-margin-y: calc($spacer / 2) !default;

// Navbar
$navbar-brand-padding-y: 0;
$navbar-nav-link-padding-x: calc($grid-gutter-width / 2);

// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

$input-group-addon-bg: $white;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75rem !default;
$alert-padding-x: .25rem;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: 0;

// Cards

$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-border-width: 0;
$card-border-radius: 8px;
$card-border-color: $gray-200;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: $white;
$card-cap-color: inherit !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: calc($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;


// Badges

$badge-font-size: calc(12/16)*1rem;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: .35em;
$badge-padding-x: .5em;
$badge-border-radius: calc(12/16)*1rem;


// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 0;

$modal-dialog-margin: .5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, .2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: 8px;
$modal-content-box-shadow-xs: 0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $white;
$modal-footer-border-color: $white;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-header-padding-y: 0;
$modal-header-padding-x: 0;
$modal-header-padding: 0; // Keep this for backwards compatibility

$modal-xl: 1140px !default;
$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform .3s ease-out !default;


// Close

$close-font-size: $font-size-base;
$close-font-weight: $font-weight-base;
$close-color: $info;
$close-text-shadow: 0 1px 0 $white !default;

// Forms

$custom-control-indicator-size: 1.5rem;
$custom-control-indicator-bg-size: 1.2rem;
$custom-control-indicator-checked-bg: $info;
$custom-control-indicator-checked-disabled-bg: rgba($info, .5);

$form-check-input-checked-color: $info;
$form-check-input-checked-bg-color: $info;
$form-check-input-focus-border: $info;
$form-check-input-focus-box-shadow: 0 0 1px 5px rgba($blue, .2);

$form-select-bg: $input-bg;
$form-select-border-width: 0;

// blockquotes
$blockquote-small-color: $gray-800;
$blockquote-small-font-size: calc(14/16)*1rem;
$blockquote-font-size: $font-size-base;

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
// $dropdown-link-active-bg: blue;
$dropdown-link-active-color: $white;
$dropdown-inner-border-radius: 8px;
