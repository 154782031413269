// ILLUSTRATIONS GENERALES
// Utilisées dynamiquement dans faire requetes / historique requetes / home page

.services {
    .illustration {
        background-size: 100px 100px !important;
        background-position: top left;
        height: 100px !important;
        width: 100px !important;
        margin: auto;

        // home

        &.home {
            width: 100px !important;
            height: 100px !important;

            &.permis-visite {
                background: url("../../content/images/illustrations/permis-visite.svg");
            }

            &.faq {
                background: url("../../content/images/illustrations/faq.svg");
            }

            &.parloirs {
                background: url("../../content/images/illustrations/parloirs.svg");
            }

            &.versements {
                background: url("../../content/images/illustrations/versements.svg");
            }
        }
    }
}
