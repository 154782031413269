.blockquote {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: 2rem;
    border-left: .25rem transparent solid;

    &-warning {
        border-left-color: $yellow;
    }
}
