@import "assets/scss/bootstrap-variables";
@import "bootstrap/scss/mixins";

/* ==============================================================
Custom header
===============================================================*/

header {
    background: $white;
    margin-bottom: 0;

    > .navbar:first-child {
        box-shadow: 0 4px 16px -16px rgba(0, 0, 0, 0.32), 0px 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }

    .navbar-brand,
    .nav-link {

        .logo-franceConnect {
            background: url("../../content/images/LogoFC_Default.svg") no-repeat;
            background-size: contain;
            height: 44px;
            width: 150px;
            display: inline-block;
            vertical-align: middle;

            &:hover {
                background: url("/content/images/LogoFC_Survol.svg") no-repeat;
                background-size: contain;
                height: 44px;
                width: 150px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    a svg:hover #couleurHover {
        fill: #2183F0;
    }

    .navbar-brand {
        margin-top: -1px;
        margin-bottom: -1px;

        @include media-breakpoint-only(xs) {
            padding-left: 0 !important;
        }
    }

    .nav-item {
        &--offset {
            padding-right: 190px;

            @include media-breakpoint-only(xs) {
                padding-right: 0;
                margin-right: auto;
            }
        }


        &:first-child:last-child .nav-link {
            line-height: 1.2 !important;
        }
    }

    .navbar-nav {
        &--fc {
            position: absolute;
            top: 6px;
            right: 0;
            text-align: center;

            @include media-breakpoint-only(xs) {
                display: none;
            }
        }
    }


    .navbar-collapse {
        .navbar-nav {
            &--offset {
                @include media-breakpoint-up(md) {
                    padding-right: 160px;
                }
            }

            &.max-with-300-custom {
                @include media-breakpoint-down(md) {
                    max-width: none;
                }
            }

            .nav-item {
                &:not(.active) .nav-link:hover {
                    @media (pointer: fine) {
                        background-color: $input-bg;
                    }
                }

                .nav-link {
                    font-family: 'ralewaymedium';
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1;
                    color: $dark-grey;

                    @include media-breakpoint-up(md) {
                        @media (pointer: fine) {
                            border-radius: 1rem;
                        }
                    }
                }

                &:not(.dropdown--account) {
                    > .nav-link {
                        padding-top: .35rem;
                        padding-bottom: .35rem;
                        border-top: calc(3/16)*1rem solid transparent;

                        span:not(.logo-franceConnect) {
                            display: block;
                            border-radius: 2px;
                            height: 4px;
                            width: calc(100% + 18px);
                            margin-bottom: 12px;
                            box-shadow: -9px 18px 0 0 transparent;
                            will-change: box-shadow;
                            transition: box-shadow .15s ease-in-out;

                            @include media-breakpoint-down(md) {
                                display: inline-block;
                                width: auto;
                                box-shadow: 0 18px 0 0 transparent;
                            }
                        }
                    }
                }

                &.active:not(.dropdown--account) {
                    background-color: transparent;

                    > .nav-link {
                        color: $ocean;
                        background-color: transparent;

                        span {
                            box-shadow: -9px 18px 0 0 $ocean;
                        }
                    }

                    &.dropdown--aide > .nav-link {
                        span {
                            box-shadow: 0 17px 0 0 $ocean;
                        }
                    }
                }

                &.dropdown--aide {
                    .dropdown-toggle {
                        border-top: 3px solid transparent !important;

                        &::before {
                            // empêche la drop de se fermer quand on passe du toggle au menu
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 0;
                            height: 15px;
                            width: 100%;
                        }
                    }

                    .dropdown-toggle {
                        position: relative;

                        &::after {
                            position: absolute;
                            top: 50%;
                            right: 8px;
                            transform: translateY(-50%);
                        }

                        span {
                            padding-right: 8px;
                        }
                    }

                    hr {
                        margin-top: 4px !important;
                        margin-bottom: 4px !important;
                    }

                    .nav-link {
                        border-top: 0;

                        &.active {
                            background-color: transparent;
                            color: $info;

                            span {
                                display: inline-block;
                                height: auto !important;
                                width: auto !important;
                                margin-bottom: 0 !important;
                                border-bottom: 2px solid $info;
                            }
                        }
                    }

                }

                &.dropdown--account {
                    .dropdown-menu {
                        left: auto;
                    }

                    .dropdown-item {
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }

                .dropdown-menu {
                    .nav-item {
                        &:not(:first-child) {
                            @include media-breakpoint-up(md) {
                                margin-top: 5px;
                            }
                        }

                    }

                    .nav-link.active {
                        cursor: default;
                    }
                }

                .dropdown:not(.dropdown--account).dropdown-active {
                    &[aria-expanded='true'] {
                        &::before {
                            opacity: 0;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 100%;
                        right: 0;
                        left: 0;
                        height: 4px;
                        background-color: $ocean;
                        border-radius: 2px;
                        opacity: 1;
                        will-change: opacity;
                        transition: opacity .15s ease-in-out;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {

        .jh-navbar-toggler {
            &[aria-expanded=true] {
                .ng-fa-icon {
                    &:first-child {
                        display: none;
                    }
                }
            }

            &[aria-expanded=false] {
                .ng-fa-icon {
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }

        .navbar-collapse {
            .navbar-nav {
                .nav-item {
                    &:not(:first-child:last-child) .nav-link {
                        border-bottom: 1px solid rgba($dark-grey, .31);
                    }

                    .nav-link {
                        padding: 1rem 0 1rem 1rem !important;
                    }
                }
            }
        }

        .navbar.navbar-expand-md.main-nav.bg-white,
        .navbar.navbar-expand-md.main-nav.bg-white .dropdown-menu {
            padding: 0 !important;
        }

        .navbar-nav {

            &.disabled-column {

                @include media-breakpoint-down(md) {
                    width: 100%;
                    justify-content: space-between;
                }

                a {
                    padding: 1rem;

                    &[aria-expanded] {
                        color: $black;
                        padding: 1rem #{calc(20/16)*1rem};

                        &:hover {
                            color: $ocean;
                        }
                    }
                }
            }
        }
    }
}

.navbar-france-connect {
    line-height: 1;
    font-family: 'robotoregular', sans-serif;
    font-size: calc(12/16)*1rem;
    color: $blue-france-connect;

    &:hover {
        text-decoration: underline;
    }
}


a svg:hover #couleurHover {
    fill: #2183F0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand {
    &.logo {
        .logo-img {
            height: 90px;
            width: 100px;
            display: inline-block;
            vertical-align: middle;
            background: url('/content/images/logo-ministere-justice.svg') no-repeat center center;
            background-size: contain;

            @include media-breakpoint-only(xs) {
                height: 71px;
            }
        }
    }
}

/* ==========================================================================
lien d'évitement
========================================================================== */
.skiplinks {
    background-color: $dark-grey;
    position: absolute;
    transform: translateY(-100%);

    &:focus-within {
        position: relative;
        transform: translateY(0);
    }

    @at-root .overlay > & {
        display: none !important;
    }

    &__list {
        list-style-type: none;
        display: inline-flex;
        margin-top: .5rem;
        margin-bottom: 1rem;
    }

    &__link {
        --link-underline: none;
        --link-blank-font: none;
        --link-blank-content: none;
        --text-spacing: 0;
        --title-spacing: 0;
        --blend-full-size: 100%;
        align-items: center;
        color: $dark-grey;
        background-color: $white;
        display: inline-flex;
        flex-direction: row;
        font-size: 1rem;
        line-height: 1.5rem;
        min-height: 2rem;
        padding: 0.25rem 0.75rem;
        margin-top: .5rem;
        margin-right: .25rem;
        z-index: 1;

        @at-root &, [href]#{&}:focus-visible {
            border-radius: 1rem;
        }
    }
}
