.nav {
    &-tab-custom {
        .nav {
            &-item {
                &--offset {
                    position: relative;
                    margin-left: -10px;
                }
            }

            &-link {
                border-top-left-radius: calc(8/16)*1rem;
                border-top-right-radius: calc(8/16)*1rem;
                color: $body-color;

                &:hover {
                    color: $info;
                }

                &.active {
                    position: relative;
                    background-color: $white;
                    color: $info;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        display: block;
                        width: 100%;
                        height: 4px;
                        background-color: $info;
                        z-index: 2;
                        border-radius: 2px;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
}
