.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 14px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;

        &:checked + .slider {
            background: lighten($blue-mid, 40%);
        }

        &:checked + .slider:before {
            transform: translateX(26px);
            background: $blue-mid;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: lighten($grey-mid, 20%);
        transition: 0.4s;

        &:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 0;
            bottom: -3px;
            background: $grey-mid;
            transition: 0.4s;
        }

        &.round {
            border-radius: 14px;

            &:before {
                border-radius: 50%;
            }
        }
    }
}
