html {

    body {
        background: $grey-light !important;
        font-family: 'robotoregular', sans-serif;
        min-height: 35rem;
    }
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
    padding: 22px 40px;

    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: $white;
    background: $red-mid !important;
    margin-bottom: 2px;
}

/* ==============================================================
Custom wrapper
===============================================================*/

.mainContent {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 104px);
    max-width: 100vw;
    background: $grey-light;
    background-image: url('../../content/images/vague.svg');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    padding-top: 40px;
    position: relative;

    @at-root .is-tunnel & {
        min-height: 100vh;
    }

    &Wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .services {
            flex-grow: 1;
        }
    }
}


/* ==============================================================
Custom card
===============================================================*/

.c-card {
    @include makeAcustomCard(calc(24/16)*1rem, calc(24/16)*1rem);

    @include media-breakpoint-only(xs) {
        padding-right: calc(12/16)*1rem;
        padding-left: calc(12/16)*1rem;
    }

    &-title {
        @include media-breakpoint-only(xs) {
            font-size: calc(20/16)*1rem;
        }
    }

    &-small {
        position: relative;

        &-no-radius-top {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }

        &-no-radius-top-left:not(#_) {
            border-radius: 0 8px 8px 8px !important;
        }

        &-no-box-shadow {
            box-shadow: none !important;
        }

        &--body {
            padding: 0 !important;
            margin: 0 40px !important;
        }

        &-title {
            font-size: calc(24/16)*1rem;
            color: $black;
            margin-bottom: calc(24/16)*1rem;
        }

        &-text {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0;
        }

        &--link {
            font-size: 16px;
            color: $ocean;
            font-weight: bold;

            &:hover {
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    width: calc(100% + 20px);
                    height: calc(100% + 8px);
                    position: absolute;
                    top: -4px;
                    left: -10px;
                    background-color: rgba(#000, .1);
                    border-radius: 16px;
                }
            }
        }

        .card-header,
        .card-footer {
            border-radius: 0 !important;
            background: transparent !important;
            border: 0 !important;
        }

        .card-header {
            padding-bottom: 2rem;
        }

        .card-footer {
            padding-top: 2rem;
            text-align: center;

            label {

            }
        }

        &--footer {
            margin-top: 30px;
        }

        &-no-radius-left {
            border-top-left-radius: 0 !important;
        }

        &.permis_inactif,
        &.permis_suspendu,
        &.rdv_parloir_annule {
            background-color: $gray-200 !important;

            .forme:after {
                background-color: $gray-700;
            }

            svg {
                color: rgba(0, 0, 0, 0.6) !important;
            }
        }
    }
}

#tableauRdv {

    .c-card {

        &:first-of-type {
            border-top-left-radius: 0 !important;
        }

        &:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }
}

.c-list {

    &-small {

        &-item {

            @include makeAlist(4px, 20px, 30px, 20px, 30px, 10px, initial);

        }

        &-link {
            @include makeAlinkSimple;
            cursor: pointer;
        }

        .switch {
            margin: 0 0 0 15px;
        }
    }
}

.c-table-stripped {

    table {

        /*thead {
            position: sticky;
            top: 0;
            z-index: 10;
        }*/
        thead, tfoot {

            tr {
                background: $grey-mid !important;

                th {
                    vertical-align: top !important;
                }
            }
        }

        th, .nowrap {
            color: $black;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        tr {
            background: $grey-light !important;

            &:nth-child(even) {
                background: darken($grey-light, 5%) !important;
            }
        }

        td {
            vertical-align: middle !important;
        }

        th, td {
            font-size: 13px;
            line-height: 14px;
            word-break: keep-all;
        }

        .numeric {
            text-align: right;
        }
    }
}

.c-empty-img {
    height: 30px;
    width: 30px;
}

.separator-find {
    margin: 35px 0;
    background: $grey-mid;
    color: $black;
    padding: 20px;
}

.c-upload {
    background: $grey-mid;
    border-radius: 25px
}

.grab {

    > div {
        cursor: grab !important;

        &.gu-transit {
            cursor: grabbing !important;
            box-shadow: -1px 2px 10px 3px rgba(0, 0, 0, 0.3) inset;
        }
    }
}

.hide,
.tox-statusbar__branding {
    display: none !important;
    opacity: 0 !important;
}

.nav-tabs {
    border-bottom: 0 !important;

    .nav-item {

        .nav-link {
            padding: 20px 30px !important;
            border: 0 !important;
            background: $grey-mid !important;
            border-radius: 0 !important;
            font-size: 15px !important;

            &.active {
                background: $white !important;
            }

            &:hover {
                border: 0 !important;
            }
        }

        &:first-of-type {

            .nav-link {
                border-top-left-radius: 4px !important;
            }
        }

        &:last-of-type {

            .nav-link {
                border-top-right-radius: 4px !important;
            }
        }
    }
}

.c-card-listing {

    .c-card {
        @include makeAcard(15px, 15px);

        border-radius: 0 !important;
        border-top: 1px solid $grey-darker;

        &:first-of-type {
            border-top-right-radius: 25px !important;
            border-top: 0 !important;
        }

        &:last-of-type {
            border-bottom-right-radius: 25px !important;
            border-bottom-left-radius: 25px !important;
        }
    }
}

.dropDownAbsolute {

    .dropdown-toggle {
        &:focus {
            border: 0 !important;
            outline: 0 none !important;
        }
    }

    .dropdown-menu {
        will-change: transform !important;
        transform: translate3d(-210px, -40px, 0px) !important;

        .dropdown-item {
            cursor: pointer;
        }
    }
}

.custom {

    /* ==============================================================
    CALENDRIERS
    ===============================================================*/
    &.calendrier {
        li {
            &.calendrier_jour {
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                color: #707070;
            }

            .ng-fa-icon {
                font-size: 18px;
                color: $black;
            }
        }

        li {
            &.calendrier_horaire {
                border-radius: 2px;
                border: solid 1px #f2f0f0;
                background-color: #f1f3f4;
                font-size: 12px;
                line-height: 1;
                text-align: center;
                color: $gray-700;

                &:hover {
                    background-color: $ocean !important;
                    color: $white !important;
                }
            }
        }
    }

    &.friendArea {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        color: $black;
        width: 100%;

        .list-group-item {
            background: #f1f3f4;

            .btn_selectFriends {
                padding-top: 13px;
                padding-bottom: 13px;
                width: 100%;
                text-align: left;
            }
        }

        .forWho {
            border-radius: 4px;
            background-color: #f1f3f4;
            border: 0;
            margin-bottom: 5px;
            cursor: pointer;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &.picto_noPermis {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.item-reservation {
    background: $grey-light;
    padding: 15px 20px;
    cursor: pointer;

    button,
    label {
        font-size: 16px !important;
        font-weight: bold !important;
        color: $black !important;
        cursor: pointer !important;
        border: 0 !important;
        padding: 15px !important;
    }

}

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.status {
    position: relative;

    &-error {

        &:before {
            border-radius: 4px;
            content: '';
            width: 6px;
            height: 40px;
            position: absolute;
            left: 0;
            top: 0;

            background: $red-mid;

            @media (max-width: 575.98px) {
                height: 35px;
            }
        }
    }

    &-warning {

        &:before {
            border-radius: 4px;
            content: '';
            width: 6px;
            min-height: 40px;
            position: absolute;
            left: 0;
            top: 0;

            background: $yellow-mid;

            @media (max-width: 575.98px) {
                height: 35px;
            }
        }
    }

    &-validate {

        &:before {
            border-radius: 4px;
            content: '';
            width: 6px;
            height: 40px;
            position: absolute;
            left: 0;
            top: 0;

            background: $green-mid;

            @media (max-width: 575.98px) {
                height: 35px;

            }
        }
    }
}

.detailVersement {
    margin-top: 10px;
    border-top: 1px solid $grey-mid;
    padding: 15px 30px;
}

.dropdown-menu {

    &.c-dropdown-menu {
        min-width: 90px;
        height: 220px;
        overflow: auto;

        &.popin {
            height: 140px;
        }
    }
}

.greffon {
    background: $ocean;
    color: $white;
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
}

.permisAssocie {

    &.permis_inactif,
    &.permis_suspendu {
        border: 1px solid rgba($red-mid, 0.6) !important;
    }
}

.badge-custom {
    background: $grey-mid;
    border-radius: 6px !important;
    letter-spacing: .3px;
}

.forme {
    height: 88px;
    width: 88px;
    color: white;
    position: relative;

    span {
        position: relative;
        z-index: 2;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50% 65% 55% 60%;
        background: $ocean;
        width: 88px;
        height: 88px;
        z-index: 1;
        transform: rotate(40deg);
    }

    span {

        font-weight: bold;
        line-height: 1;

        &:nth-of-type(1) {
            font-size: 23px;
        }

        &:nth-of-type(2) {
            font-size: 14px;
        }
    }
}

.dropDownAbsolute {
    position: absolute !important;
    right: 15px;
    top: 15px;
}

.custom {


    &.btnActionPrincipale {
        /* ==============================================================
        BTN
        ===============================================================*/

        @include gradient(#006072, $ocean, vertical);

        padding: 15px 31px;
        font-size: 14px;
        line-height: 14px;
        color: $white;
        border-radius: 25px;

        &:hover {
            color: $white;
            @include gradient(#006072, #006072, vertical);
        }

        &.btn.disabled,
        &.btn:disabled {
            opacity: 0.45;
        }
    }

    .btnSelection {
        border-radius: 24px;
        border: solid 1px #f2f0f0;
        background-color: #f1f3f4;
        padding: 15px 31px;

        &:hover {
            background-color: #c5cdd1;
        }
    }
}

// popin
.scroll-shadow-indicator {
    $scroll-shadow-transparency: .3;
    overflow: auto;
    max-height: 30em;

    //background:
    //        /* Shadow covers */
    //        linear-gradient(white 30%, rgba(255,255,255,0)),
    //        linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
    //
    //          /* Shadows */
    //        radial-gradient(50% 0, farthest-side, rgba(0,0,0,$scroll-shadow-transparency), rgba(0,0,0,0)),
    //        radial-gradient(50% 100%,farthest-side, rgba(0,0,0,$scroll-shadow-transparency), rgba(0,0,0,0)) 0 100%;
    background: /* Shadow covers */
        linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
            /* Shadows */
            /* either straight */
            //linear-gradient(rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 90%),
            //linear-gradient(rgba(0,0,0,0) 10%, rgba(0,0,0,.5) 100%) 0 100%;
            /* or elliptic */
        radial-gradient(ellipse at top, rgba(0, 0, 0, $scroll-shadow-transparency) 20%, rgba(0, 0, 0, 0) 80%),
        radial-gradient(ellipse at bottom, rgba(0, 0, 0, $scroll-shadow-transparency) 20%, rgba(0, 0, 0, 0) 80%) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

.centered {
    margin: auto;
    text-align: left;
    vertical-align: middle;
}


// video

.wrapper-video {

    video {
        width: 100%;
    }
}


// plan du site
.border-right {
    border-right: 1px solid $grey-mid;
}

.liste-chevron {

    &:before {
        content: "> ";
        width: 15px;
        display: inline-block;
    }
}

.liste-carre {
    &:before {
        content: "\0025A0";
        width: 15px;
        display: inline-block;
        margin-left: 15px;
    }
}

.animation-transition {
    transition: all 1s ease-out;
}

/* ==============================================================
   onglets rdv et permis de visites
   ===============================================================*/

ned-rdv-parloir-consulter-filtre {
    padding-bottom: 4px;
}

ned-permis-visite-consulter-filtre {
    padding-bottom: 4px;
}
