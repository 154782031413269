.picto {

    &.parloirs {
        background-size: 16px 16px !important;
        width: 16px;
        height: 16px;

        &.calendar {
            background: url('../../content/images/pictos/utils/white-calendar.png') no-repeat center center;
        }

        &.plus {
            background: url('../../content/images/pictos/utils/plus-calendar.png') no-repeat center center;
        }

        &.pen {
            background: url('../../content/images/pictos/utils/pen-calendar.png') no-repeat center center;
        }

        &.erase {
            background: url('../../content/images/pictos/utils/erase-calendar.png') no-repeat center center;
        }
    }

    &.utils {
        display: block;
        background-size: 20px 20px !important;
        background-position: center !important;
        width: 20px;
        height: 20px;
        transform-origin: center;

        &.next {
            background: url('../../content/images/pictos/utils/back.png') no-repeat center center;
            transform: rotate(180deg);
        }

        &.back {
            background: url('../../content/images/pictos/utils/back.png') no-repeat center center;
        }

        &.calendar {
            background: $picto-calendar-white no-repeat center center transparent;
        }

        &.noPermis {
            background: url('../../content/images/pictos/color/noPermis.svg') no-repeat center center;
        }

        &.picto-card {
            background: $picto-card-white no-repeat center center transparent;
        }

        &.permis {
            background: url('../../content/images/pictos/utils/permis.png') no-repeat center center;
        }

        &.carte {
            background: url('../../content/images/pictos/card.svg') no-repeat center center;
        }

        &.delete {
            background: url('../../content/images/pictos/utils/delete.png') no-repeat center center;
        }

        &.picto-user {
            background: url('../../content/images/pictos/utils/account.png') no-repeat center center;
            margin-right: 10px;
            display: inline-block;
            width: 30px !important;
        }
    }

    &.color {

        background-size: 60px 60px !important;
        background-position: center !important;
        width: 60px;
        height: 60px;

        &.cb {
            background: url('../../content/images/pictos/color/CB.png') no-repeat center center;
        }

        &.calendar {
            background: url('../../content/images/pictos/color/calendar.png') no-repeat center center;
        }

        &.noPermis {
            background: url('../../content/images/pictos/color/noPermis.svg') no-repeat center center;
        }

        &.versements {
            background: url('../../content/images/illustrations/versements.svg') no-repeat center center;
        }

        &.xs {

            background-size: 20px 20px !important;
            background-position: center !important;
            width: 20px;
            height: 20px;

            &.calendar {
                background: url('../../content/images/pictos/color/calendar.png') no-repeat center center;
            }

            &.noPermis {
                background: url('../../content/images/pictos/color/noPermis.svg') no-repeat center center;
            }
        }
    }

    &.group {
        width: 158px;
        height: 20px;
        display: inline-block;
        background-size: 158px 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../content/images/pictos/divers/group.png');
    }

    &.fake-checkbox {
        background-size: 30px 30px !important;
        background-position: center !important;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        display: inline-block;

        &-unselect {
            background: url('../../content/images/pictos/form/unselect.png') no-repeat center center;
        }

        &-selected {
            background: url('../../content/images/pictos/form/selected.png') no-repeat center center;
        }

    }

    &.search {
        width: 30px;
        background: url('../../content/images/pictos/search.svg') no-repeat center center;
    }

    &.alert-info-plein {
        display: block;
        background-size: 20px 20px !important;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../content/images/pictos/alert-info-plein.svg');
        min-width: 20px;
        height: 20px;
        transform-origin: center;
    }

    &.statut {
        display: block;
        background-size: 10.67px 14.67px;
        background-position: center;
        width: 15px;
        height: 17px;
        transform-origin: center;

        &.en-cours {
            background: url('../../content/images/pictos/statut-en-cours.svg') no-repeat center center;
        }

        &.execute {
            background: url('../../content/images/pictos/statut-execute.svg') no-repeat center center;
        }

        &.rejete {
            background: url('../../content/images/pictos/statut-rejete.svg') no-repeat center center;
        }

        &.selectionne {
            &.en-cours {
                background: url('../../content/images/pictos/statut-en-cours-selectionne.svg') no-repeat center center;
            }

            &.execute {
                background: url('../../content/images/pictos/statut-execute-selectionne.svg') no-repeat center center;
            }

            &.rejete {
                background: url('../../content/images/pictos/statut-rejete-selectionne.svg') no-repeat center center;
            }
        }
    }

    &.filtre-selectionne {
        display: block;
        background: url('../../content/images/pictos/filtre-selectionne.svg') no-repeat center;
        background-size: 13.33px 13.33px;
        width: 13.33px;
        height: 13.33px;
        transform-origin: center;
    }

    &.pas-de-resultat {
        display: block;
        width: 112.5px;
        height: 125px;
        transform-origin: center;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../content/images/pictos/pas-de-resultat.svg');
    }
}

.video {
    display: block;
    background-size: 200px 200px !important;
    background-position: center !important;
    width: 200px;
    height: 200px;
    margin: auto !important;

    &.video-picto {
        background: url('../../content/images/pictos/utils/picto-gp.svg') no-repeat center center;
    }
}

.illustration {
    background-size: 120px 120px !important;
    background-position: center !important;
    width: 120px;
    height: 120px;
    margin: auto !important;

    &.calendar {
        background: url('../../content/images/illustrations/calendar.png') no-repeat center center;
    }

    &.noPermis {
        background: url('../../content/images/pictos/color/noPermis.svg') no-repeat center center;
    }

    &.xl {
        background-size: 150px 122px !important;
        background-position: center !important;
        width: 150px;
        height: 150px;
        margin: auto !important;

        &.logomj {
            background: url('../../content/images/logo-mj-375.png') no-repeat center center;
        }
    }
}
