label {
    margin-bottom: .5rem;
}

.form-control {
    &.ng-invalid.ng-dirty,
    &.is-invalid {
        border-width: 1px;
    }

    &.ng-invalid.ng-dirty {
        border-color: $danger;

        @if $enable-validation-icons {
            padding-right: $input-height-inner;
            background-repeat: no-repeat;
            background-position: center right calc(#{$input-height-inner} / 4);
            background-size: calc(#{$input-height-inner} / 2) calc(#{$input-height-inner} / 2);
            background-image: $form-feedback-icon-invalid;
        }

        &:focus {
            border-color: $danger;
            box-shadow: 0 0 0 $input-focus-width rgba($danger, .25);
        }

        ~ .invalid-feedback,
        ~ .invalid-tooltip {
            display: block;
        }
    }
}

input,
select.custom-select {

    &.form-control {
        font-stretch: normal;
        line-height: 1;
        color: $black;
        padding-inline: 20px;
    }
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
    cursor: pointer;
}

.label-form {
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

.invalid-feedback {
    p {
        font-size: calc(14/16)*1rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: $red-mid;
    }
}

.c-card {

    #adresse-mail,
    #numero-tel-flag {
        border-radius: calc(4/16)*1rem !important;
        font-size: calc(12/16)*1rem !important;
    }
}

.required::after {
    content: " *";
    color: $danger;
}

.information-required::before {
    margin-left: auto;
    content: " *";
    color: $danger;
}

ngb-typeahead-window {
    // autocomplete dropdown

    &.dropdown-menu {
        border: 0;
        border-radius: 0;
        padding: calc(5/16)*1rem;
        margin-top: 3px;
        box-shadow: 0 2px 4px 0 rgba($black, 0.12);

        button,
        button div {
            cursor: pointer;
        }
    }
}

.input-group {

    .input-group-text,
    .input-group-prepend {
        font-size: calc(14/16)*1rem;

        & + .form-control,
        & + .custom-select {
            margin-top: 0 !important;
            border-radius: 0 4px 4px 0;
            border: 0 !important;
            font-size: 14px;

            &::placeholder {
                font-size: 14px;
                color: $grey;
            }
        }

    }
}

@include media-breakpoint-only(xs) {
    label {

        &.label-form {
            font-size: 14px !important
        }
    }

    div {

        &.justify-content-sm-center {
            justify-content: center;
        }
    }

    .label-form {
        font-size: 14px !important
    }
}
